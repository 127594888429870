import React, { useEffect, useState } from "react";

import { Link, withRouter } from "react-router-dom";
import { Translation } from "react-i18next";

import "./style.css";
import Header from "../../../components/Header";

const HelpView = (props) => {
  const [page, setPage] = useState("/");
  const [lang, setLang] = useState("en");

  useEffect(() => {
    setLang(props.user.language);
  }, [props.user]);

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    let category = params.get("articles");
    let changelog = params.get("changelog");
    let newPage;
    if (changelog) {
      newPage = "?page_id=517";
      setPage(newPage);
      return;
    }
    if (category) {
      category = category.split('"')[1].split('"')[0];
    }
    switch (category) {
      case "gettingStarted":
        newPage = "?doc_category=getting-started";
        break;
      case "educational":
        newPage = "?doc_category=educational";
        break;
      case "templates":
        newPage = "?doc_category=templates";
        break;
      case "campaigns":
        newPage = "?doc_category=campaigns";
        break;
      case "links":
        newPage = "?doc_category=links";
        break;
      case "teams":
        newPage = "?doc_category=teams";
        break;
      case "settings":
        newPage = "?doc_category=settings";
        break;
      case "urlShorteners":
        newPage = "?doc_category=url-shorteners";
        break;
      case "workflowManagement":
        newPage = "?doc_category=workflow-management";
        break;
      case "billing":
        newPage = "?doc_category=billing";
        break;
      case "utmConventions":
        newPage = "?doc_category=utm-conventions";
        break;
      case "features":
        newPage = "?doc_category=features";
        break;
      case "parameters":
        newPage = "?doc_category=parameters";
        break;
      default:
        newPage = "/";
        break;
    }
    setPage(newPage);
  }, [window.location.search]);

  return (
    <div id="settings-organization">
      <Translation>
        {(t) => (
          <React.Fragment>
            <Header
              title={t("menu.barra_esquerda.level_2.help_center")}
              pages={[
                { title: "Centro de Ajuda", url: "/help" },
                {
                  title: t("menu.barra_esquerda.level_2.help_center"),
                  url: "/help",
                },
              ]}
            ></Header>
            <div id="help" style={{ flexGrow: 1 }}>
              <iframe
                src={`http://34.88.56.89/${page}`}
                title="Help"
                frameborder="0"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </React.Fragment>
        )}
      </Translation>
    </div>
  );
};

export default HelpView;
