import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Badge, Button, Dropdown, Icon, IconButton } from "rsuite";
import menuHide from "./../../../helpers/menuHide";
import ReactTooltip from "react-tooltip";

import "./style.css";
import { Translation } from "react-i18next";
import UserMenu from "../../UserMenu";

function toggleUserMenu() {
	var menu = document.getElementById("user-menu");
	menu.classList.toggle("open");
}

function Navbar(props) {
	const [notificationCount, setNotificationCount] = React.useState(0);
	useEffect(() => {
		if (process.env.REACT_APP_ENV !== "qld") return;
		window.addEventListener("productfruits_ready", function () {
			window.productFruits.api.announcementsV2.listen("newsfeed-unread-count-changed", (data) => {
				const unreadCount = data.count;
				setNotificationCount(unreadCount);
			});
			window.productFruits.api.announcementsV2.attachNewsWidgetToElement(
				document.querySelector("#pf-announcements")
			);
		});
	}, []);
	return (
		<Translation>
			{(t) => (
				<div className="navbar">
					<div className="header">
						<Dropdown
							renderTitle={() => {
								return <IconButton icon={<Icon icon="th2" />} />;
							}}
						>
							<h5 className="drawer-label">{t("menu.barra_topo.dropdown.all_apps")}</h5>
							<Dropdown.Item className="active">
								<img src="/logo/klein_blue_logo.png" alt="" /> URL Builder Online
							</Dropdown.Item>
							<a
								href={
									process.env.REACT_APP_ENV === "prod"
										? "https://magical-brigadeiros-adb380.netlify.app"
										: "https://development--magical-brigadeiros-adb380.netlify.app"
								}
								target="_blank"
								rel="noreferrer"
							>
								<Dropdown.Item className="active">Deployer</Dropdown.Item>
							</a>
						</Dropdown>
						<Link to="/">
							<img src={`/logo/${props.theme}_logo.png`} alt="" />
							<h1 className="title">urlbuilder.online</h1>
						</Link>
					</div>
					<div className="user-info">
						{/*
        <BulkProgress />
         */}
						{props.user && props.user.orgAdmin && (
							<Link to="/teams/user-management/create">
								<button className="action-button-2">
									<Icon icon="user-plus" style={{ marginRight: "0.5em", fontSize: "16px" }} />
									{t("menu.barra_topo.button.invite_member")}
								</button>
							</Link>
						)}
						{process.env.REACT_APP_ENV === "qld" ? (
							<Button id="pf-announcements">
								<Icon icon="bell" className={notificationCount ? "unread" : ""} />
								{notificationCount ? <h5>{notificationCount}</h5> : <></>}
							</Button>
						) : (
							<></>
						)}

						<UserMenu {...props} />
						<ReactTooltip
							className="nav-tooltip"
							id="nav-tip"
							place={"bottom"}
							type={"light"}
							effect={"solid"}
							multiline={true}
							delayShow={500}
						></ReactTooltip>
					</div>
				</div>
			)}
		</Translation>
	);
}

export default Navbar;
